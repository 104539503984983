.content-box {
    padding: 5rem 0 0 0;

    h1 {
        font-size: 6.3rem;
        color: $iBlack;
        font-family: $neueBlack;
        line-height: 120px;
        margin-bottom: $size-26;
        letter-spacing: 3px;
    }

    p {
        @extend .fz-14;
        margin-top: $size-26;
        letter-spacing: 1px;
        color: $iBlack1;
        // max-width: 743px;
    }

    &-banner {
        height: 422px;
        position: relative;
        z-index: 1;
    }

    .page-back-btn {
        margin: 0 0 $size-47 0;

        a {
            color: #ED7821;
            transition: all .3s ease;
            opacity: 1;
            @extend .fz-14;
            font-family: $neueMid;

            &:hover {
                opacity: .5;
            }
        }

        &-icon {
            width: 22px;
            height: 10px;
            display: inline-block;
            background: url('../img/arrow-back.svg') no-repeat;
            margin-right: 8px;
        }
    }
}

@media screen and (max-width: 991px) {
    .content-box h1 {
        font-size: 5.5rem;
    }
}

@media screen and (max-width: 767px) {
    .content-box h1 {
        max-width: 70%;
        font-size: 3.2rem;
        line-height: 1;
    }

    .content-box-banner {
        height: 200px;
    }

    .content-box .page-back-btn {
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 575px) {
    .content-box-banner {
        height: 175px;
    }

    .content-box h1 {
        max-width: 76%;
    }
}

@media screen and (max-width: 480px) {
    .content-box h1 {
        &.ex-smFont {
            font-size: 3.6rem;
        }
    }
}

@media screen and (max-width: 374px) {
    .content-box h1 {
        max-width: 80%;
        // font-size: 2rem;

        &.ex-smFont {
            font-size: 3.1rem;
        }
    }
}