// Custom Variables //
$primaryFont: 'NeueHaasDisplay-Roman';
$neueMid: 'NeueHaasDisplay-Mediu';
$neueBlack: 'NeueHaasDisplay-Black';
// Set Colors
$black: #000000;
$white: #ffffff;

$iPrimary: #434D4B;
$iOrang: #ED7800;
$iBlack: #232726;
$iBlack1: #434C4A;
$iPink: #D988D3;
$iPGreen: #5ED3CC;
$iGreen: #74D896;
$iLOrang: #FF964B;
$iMustard: #F7F3F1;
$iGrey: #F8F8F8;

$iYellow: #fdc340;
$iMagenta: #b9287e;

// Media Sizes
$vpdesktopMin: "(min-width:2100px)";
$vpMddesktop: "(max-width:1700px)";
$vpdesktop: "(max-width:1400px)";
$vptabletL: "(max-width:1229px)";
$vptabletP: "(max-width:991px)";
$vpmobile: "(max-width:767px)";
$vpsmmobile: "(max-width:400px)";
$vpxsmobile: "(max-width:359px)";

// Font Sizes
$size-12: 12px;
$size-14: 16px; // 14 to 16
$size-16: 1rem;
$size-18: 1.125rem;
$size-20: 1.25rem;
$size-22: 1.375;
$size-24: 1.5rem;
$size-26: 1.625rem;
$size-28: 1.75rem;
$size-29: 1.813rem;
$size-30: 1.875rem;
$size-32: 2rem;
$size-34: 2.125rem;
$size-36: 2.25rem;
$size-40: 2.5rem;
$size-47: 2.9375rem;
$size-63: 3.9375rem;
$size-72: 4.5rem;
$size-80: 5rem;
$size-100: 6.25rem;


.fz-14 {
    font-size: 16px; // 14 to 16
    @media (max-width: 767px) {
        font-size: 14px;
    }
}