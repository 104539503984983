.animate-item {
    position: relative;

    // overflow: hidden;
    &-circle {
        width: 300px;
        height: 500px !important;
        background-color: transparent !important;
        position: absolute !important;
        left: -150px;
        top: 0;

        &.right {
            left: auto;
            right: -160px;
        }

        @media(max-width:992px) {
            display: none;
        }
    }

    &-dual-arrow {
        width: 300px;
        height: 750px !important;
        background-color: transparent !important;
        position: absolute !important;
        right: 0;
        top: 40%;
        transform: translateY(-50%);

        &.left {
            right: auto;
            left: -30px;
        }
    }

    &-cross-x {
        width: 300px;
        height: 500px !important;
        background-color: transparent !important;
        position: absolute !important;
        left: -150px;
        bottom: 150px;

        &.right {
            left: auto;
            right: -156px;
        }
    }

    &-col-lines {
        width: 300px;
        height: 500px !important;
        background-color: transparent !important;
        position: absolute !important;
        right: -120px;
        bottom: 20%;
        z-index: 1;

        &.right {
            right: auto;
            left: -150px;
        }
    }
}

@media screen and (max-width: 991px) {
    .animate-item {

        .animate-item-circle,
        .animate-item-dual-arrow,
        .animate-item-cross-x,
        .animate-item-col-lines {
            display: none;
        }
    }
}