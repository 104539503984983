.sec-head {
    font-size: $size-47;
    letter-spacing: 1px;
    font-family: $neueMid;
    color: $iBlack;
}

.mission-vision {
    .display-flex {
        display: flex;
    }

    .animate-item-cross-x {
        bottom: 125px;
    }

    .animate-item-circle {
        top: 260px;
    }

    &-text {
        height: 100%;
        padding: 0 $size-40 0 0;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: 50%;

        p {
            @extend .fz-14;
            letter-spacing: 1px;
            color: $iBlack1;
        }
    }

    &-items {
        height: 100%;
        min-height: 240px;
        border-left: 3px solid #74d896;
        padding: 0 $size-40;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: 50%;

        @media (min-width: 1450px) {
            min-width: 730px;
        }

        &-text {
            width: 100%;
            font-size: $size-47;
            letter-spacing: 1px;
            line-height: 55px;
            font-family: $neueMid;
            color: $iBlack;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            @media (max-width: 767px) {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.board-of-directors {
    overflow-x: hidden;
    width: 100%;

    .bod-slider {
        margin-top: $size-47;

        .item {
            cursor: pointer;
            &-img {
                position: relative;

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0.5;
                    transition: all 0.5s ease;
                }
            }

            &-head {
                margin-top: $size-20;
                font-size: $size-24;
                font-family: $neueMid;
                color: $iBlack;
            }

            &-text {
                @extend .fz-14;
                margin-top: 5px;
                color: $iBlack1;
            }

            &:hover {
                .item-img:after {
                    opacity: 0;
                }
            }
        }

        .owl-item {
            &:nth-of-type(4n + 1) {
                .item .item-img:after {
                    background-color: #c88eb3;
                }
            }

            &:nth-of-type(4n + 2) {
                .item .item-img:after {
                    background-color: #73c097;
                }
            }

            &:nth-of-type(4n + 3) {
                .item .item-img:after {
                    background-color: #ee9d66;
                }
            }

            &:nth-of-type(4n + 4) {
                .item .item-img:after {
                    background-color: #6ac1bc;
                }
            }
        }

        .owl-nav {
            position: absolute;
            top: 50%;
            margin-top: -30px;
            transform: translateY(-10px);
            left: -50px;
            width: calc(100% + 100px);
            height: 0;
            display: flex;
            justify-content: space-between;
            button {
                border: none;
                outline: none;
            }

            .owl-nav-left,
            .owl-nav-right {
                width: 28px;
                height: 20px;
                display: inline-block;
                background: url('../img/slider-nav-left.svg') no-repeat;
            }

            .owl-nav-right {
                background: url('../img/slider-nav-right.svg') no-repeat;
            }
        }
    }
}

.strategic-parters {
    &-list {
        display: flex;
        flex-wrap: wrap;

        &-item {
            width: 20%;
            height: 150px;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;

            img {
                max-width: 50%;
                opacity: 0.5;
                transition: all 0.3s ease;
                align-self: center;
                filter: grayscale(1);
                @media (max-width: 500px) {
                    max-width: 60%;
                }
            }

            &:hover {
                img {
                    opacity: 1;
                }
            }
        }
    }
}

.strategy {
    &-text {
        .sec-head {
            margin-bottom: $size-20;
        }

        p {
            line-height: 1.8;
            letter-spacing: .5px;
        }
    }

    &-img {
        width: 360px;
        height: 360px;
        position: relative;
        display: inline-block;
        margin: 45px 0 0 0;

        .bar-mask {
            width: calc(100% + 1px);
            position: absolute;
            left: -1px;
            bottom: -1px;
            background: url("../img/bar-mask.svg") no-repeat;
            height: calc(100% + 2px);
            background-size: cover;
            background-position: left top;
        }
        &.img--cricle {
            margin: 30px auto 0;
            display: block;
            @media (min-width: 920px) {
                width:  400px;
                height: 400px;
            }
            .bar-mask {
                background-image: url('../img/circle-mask.svg');
            }
        }
    }
}

.strategic-parters-list {
    justify-content: center;
}
@media screen and (max-width: 992px) {
    .strategic-parters-list-item {
        width: 33.3%;
    }
}
@media (max-width: 500px) {
    .strategic-parters-list-item {
        width: 50%;
    }
}

@media screen and (max-width: 767px) {
    .mission-vision .display-flex {
        flex-wrap: wrap;
        align-self: center;
    }

    .mission-vision-text {
        width: 100%;
    }

    .mission-vision-items {
        width: 100%;
        order: -1;
        min-height: auto;
        padding: 0;
        border-left: none;
    }

    .mission-vision-items-text {
        font-size: 2.1rem;
        line-height: 40px;
    }

    .sec-head {
        font-size: 2.1rem;
    }

    .board-of-directors .bod-slider .item-head {
        font-size: 1.172rem;
    }

    .owl-carousel .owl-stage-outer {
        overflow: visible;
    }

    .strategy .text-align-right {
        text-align: center;
    }

    .strategy-img {
        width: 342px;
        height: 342px;
    }
}

@media screen and (max-width: 575px) {
    .strategic-parters-list-item {
        height: 100px;
    }
}

@media screen and (max-width: 370px) {
    .strategy-img {
        width: 290px;
        height: 290px;
    }
}