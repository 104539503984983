.services-list {
    margin-bottom: 150px;
    @media (max-width: 767px) {
        padding-bottom: 55px;
        margin-bottom: 20px !important;
    }
    &-item {
        width: 100%;
        max-width: 513px;
        margin: 0 auto;
        position: relative;
        transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);

        // @media (min-width: 1367px) {
        //     max-width: 715px;
        // }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(67, 77, 75, .5);
            transition: all .3s ease;
            opacity: 0;
            display: none;
        }

        &-img {
            height: 436px;
            overflow: hidden;

            // @media (min-width: 1367px) {
            //     height: 608px!important;
            // }

            .divimage {
                background-repeat: no-repeat;
                background-size: auto 100%;
                transition: background-size .3s ease;
            }
        }

        &-text {
            position: absolute;
            left: 0;
            bottom: -35px;
            transition: all .3s ease;
            pointer-events: none;
            font-family: $neueMid;
            width: 75%;
            background-color: transparent;
            padding: 40px 40px 25px;
            z-index: 1;

            h3 {
                font-size: $size-29;
                margin-bottom: 1.6rem;
                color: $white;
            }

            .text-link {
                @extend .fz-14;
                letter-spacing: .5px;
                color: $iPrimary;
                transition: all .3s ease;
            }

            .read-more-btn {
                color: $white;
                padding-right: 15px;

                .read-more-btn-icon {
                    &:before {
                        background-color: $white;
                    }

                    &:after {
                        border-right-color: $white;
                        border-bottom-color: $white;
                    }
                }

                &:after {
                    border-color: $white;
                }
            }
        }

        .upper-link {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }

        &.bd_cl1 {
            .services-list-item-text {
                background-color: $iPink;
            }
        }

        &.bd_cl2 {
            .services-list-item-text {
                background-color: $iPGreen;
            }
        }

        &.bd_cl3 {
            .services-list-item-text {
                background-color: $iGreen;
            }
        }

        &.bd_cl4 {
            .services-list-item-text {
                background-color: $iOrang;
            }
        }

        &.bd_cl5 {
            .services-list-item-text {
                background-color: $iPGreen;
            }
        }

        &.bd_cl6 {
            .services-list-item-text {
                background-color: $iPink;
            }
        }

        &:hover {
            &:after {
                // opacity: 1;
            }

            .services-list-item-img .divimage {
                // background-size: auto 120%;
                // filter: grayscale(100%);
            }

            .services-list-item-text {
                //left: 30px;
                // pointer-events: all;
                // color: $white;
                // z-index: 1;

                .text-link {
                    color: $white;
                    border-color: $white !important;

                    &:hover {
                        opacity: .5;
                    }
                }
            }
        }

        &.sli--a {
            &.center-item {
                @media (min-width: 768px) {
                    margin-top: 50px;
                    @media (max-width: 1080px) {
                        max-width: 50%;
                    }
                }
            }
            .services-list-item-img {
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 70%;
                    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.01) 1%,rgba(35,39,38,1) 100%);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    opacity: .4;
                }
            }
            .box-txt {
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                padding: 30px;
                z-index: 2;
                h2 {
                    font-size: 29px;
                    line-height: 1em;
                    font-weight: bold;
                    color: #fff;
                    display: block;
                    margin-bottom: 12px;
                }
                .forward-arrow {
                    display: block;
                    width: 27px;
                    height: 20px;
                    background: url('../img/arrow-circle.svg') no-repeat center center / 100% auto;
                }
            }
            .services-list-item-text {
                background: transparent;
                padding: 0;
                width: 220px;
                left: auto;
                right: 70px;
                bottom: -83px;
                .shape {
                    display: block;
                    background: transparent url('../img/shape-line.svg') no-repeat center center / 100% auto;
                    display: block;
                    width:  220px;
                    height: 220px;
                }

                &.shape--arrows {
                    bottom: auto;
                    top: -80px;
                    .shape {
                        background-image: url('../img/shape-arrow.svg');
                    }
                }

                &.shape--circle {
                    bottom: -50px;
                    .shape {
                        background-image: url('../img/shape-circle.svg');
                    }
                }

                @media (max-width: 1023px) {
                    width: 150px;
                    right: 20px;
                    bottom: -60px;
                    .shape {
                        width: 150px;
                        height: 150px;
                    }
                    &.shape--arrows {
                        bottom: auto;
                        top: -20px;
                    }
                }
                @media (max-width: 767px) {
                    width: 130px;
                    right: 20px;
                    bottom: -30px;
                    .shape {
                        width: 130px;
                        height: 130px;
                    }
                    &.shape--arrows {
                        bottom: auto;
                        top: 20px;
                    }
                    &.shape--circle {
                        bottom: -30px;
                    }
                }
            }
        }
    }

    .col-12 {
        &:nth-of-type(even) {
            .services-list-item {
                margin: $size-100 auto 3.1rem;
            }
        }

        // &:nth-of-type(4n+2) {
        //     .services-list-item {
        //         a {
        //             border-color: $iPGreen;
        //         }
        //     }
        // }
        // &:nth-of-type(6n+3) {
        //     .services-list-item {
        //         a {
        //             border-color: $iGreen;
        //         }
        //     }
        // }
        // &:nth-of-type(8n+4) {
        //     .services-list-item {
        //         a {
        //             border-color: $iOrang;
        //         }
        //     }
        // }
    }

    .animate-item-dual-arrow {
        right: inherit;
        top: 50%;
        left: -100px;
        transform: translateY(-50%) rotate(180deg);
    }

    .animate-item-cross-x {
        left: inherit;
        bottom: 150px;
        right: -65px;
    }
}