.connect-with-us {
    &-list {
        margin: 40px 0 0 0;

        &-item {
            &-img {
                height: 80px;
            }

            &-text {
                strong {
                    display: block;
                    font-size: $size-24;
                    letter-spacing: 1px;
                    margin-bottom: 5px;
                    font-family: $neueMid;
                }
            }
        }
    }

    .animate-item-dual-arrow {
        margin-top: 200px;
    }
}

@media screen and (max-width: 992px) {
    .connect-with-us-list .col-lg-3:not(:last-of-type) {
        margin-bottom: 20px;
    }
}