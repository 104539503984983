html {
    height: 100%;
    direction: ltr;
    width: 100%;
    font-size: 16px;

    &.browserIos {
        overflow-x: hidden;
    }
}

@media screen and (max-width: 1366px) {
    html {
        font-size: 14px;
    }
}

body {
    height: 100%;
    // width: 100vw !important;
    position: relative;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;
    background: $white;
    font-family: $primaryFont;
    color: $iBlack1;
    -webkit-overflow-scrolling: auto;
}

@media screen and (max-width: 767px) {
    .no-x-scroll {
        max-width: 100vw;
        overflow: hidden;
    }
}

.container {
    max-width: 1613px;
    // &.modify {
    //     @media(min-width:1266px) {
    //         max-width: 1166px;
    //     }
    //     @media(min-width:1713px) {
    //         max-width: 1613px;
    //     }
    // }
}

::-moz-selection {
    background: $iYellow;
    text-shadow: none;
}

::selection {
    background: $iYellow;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

*,
*:after,
*:before {
    outline: none;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none !important;
    outline: none !important;
    color: $iMagenta;

    &:hover {
        color: $iPink;
    }
}
.building-brands-content-text h2 {
    font-weight: bold;
    margin-bottom: 20px;
}
.c-ul li,
.text-layout-text li,
.building-brands-content-text ul,
p {
    line-height: 1.6;
    margin: 10px 0 25px;
    font-size: 16px;
    letter-spacing: .5px;

    i {
        font-style: italic;
    }

    @media #{$vpmobile} {
        font-size: 14px;
    }

    @media (max-width: 575px) {
        font-size: 13px;
    }
}

svg {
    transform: translateZ(0);
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.o-hidden {
    overflow: hidden;
    height: 100%;
}

.u-hide {
    display: none !important;
}

.dnone {
    display: none !important;
}

.u-pos-s {
    position: static;
}

.u-pos-r {
    position: relative;
}

.pl {
    &-20 {
        padding-left: 20px;
    }

    &-25 {
        padding-left: 25px;
    }

    &-40 {
        padding-left: 40px;
    }

    &-50 {
        padding-left: 50px;
    }
}

.pr {
    &-20 {
        padding-right: 20px;
    }

    &-25 {
        padding-right: 25px;
    }

    &-40 {
        padding-right: 40px;
    }

    &-50 {
        padding-right: 50px;
    }
}

.pt {
    &-20 {
        padding-top: 20px;
    }

    &-25 {
        padding-top: 25px;
    }

    &-40 {
        padding-top: 40px;
    }

    &-50 {
        padding-top: 50px;
    }
}

.pb {
    &-20 {
        padding-bottom: 20px;
    }

    &-25 {
        padding-bottom: 25px;
    }

    &-40 {
        padding-bottom: 40px;
    }

    &-50 {
        padding-bottom: 50px;
    }
}

.ml {
    &-20 {
        margin-left: 20px;
    }

    &-25 {
        margin-left: 25px;
    }

    &-40 {
        margin-left: 40px;
    }

    &-50 {
        margin-left: 50px;
    }
}

.mr {
    &-20 {
        margin-right: 20px;
    }

    &-25 {
        margin-right: 25px;
    }

    &-40 {
        margin-right: 40px;
    }

    &-50 {
        margin-right: 50px;
    }
}

.mt {
    &-20 {
        margin-top: 20px;
    }

    &-25 {
        margin-top: 25px;
    }

    &-40 {
        margin-top: 40px;
    }

    &-50 {
        margin-top: 50px;
    }
}

.mb {
    &-20 {
        margin-bottom: 20px;
    }

    &-25 {
        margin-bottom: 25px;
    }

    &-40 {
        margin-bottom: 40px;
    }

    &-50 {
        margin-bottom: 50px;
    }
}

.toSvg {
    max-width: 100%;
    max-height: 100%;

    polygon,
    path {
        transition: fill 0.35s linear;
    }
}

input::-ms-clear {
    display: none;
}

.primaryfont {
    font-family: $primaryFont;
}

.for--mobilehide {
    @media #{$vpmobile} {
        display: none !important;
    }
}

.for--mobileshow {
    @media (min-width: 768px) {
        display: none !important;
    }
}

.for--tablethide {
    @media #{$vptabletP} {
        display: none !important;
    }
}

.for--tabletshow {
    @media (min-width: 992px) {
        display: none !important;
    }
}

.grayScale {
    >img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all 0.5s ease;
    }

    &:hover {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }

    @media #{$vptabletP} {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }
}

.bdCenter {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        width: 1px;
        height: 100%;
        background-color: #e3e4e7;
    }
}

.nopdlr--mobile {
    @media #{$vpmobile} {
        padding-left: 0;
        padding-right: 0;
    }
}

[class^="box-"] {
    display: none;

    &.showfirst {
        display: block;
    }
}

.gridView {
    >ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -15px;
        flex-wrap: wrap;

        >li {
            padding: 15px;
            flex: 0 0 25%;

            .programmeExperience {
                width: 100%;
            }

            .IE & {
                flex: 0 0 23%;
            }
        }
    }

    @media (max-width: 1550px) {
        >ul {
            >li {
                flex: 0 0 33.33%;
            }
        }
    }

    @media (max-width: 1275px) {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }

    @media #{$vptabletP} {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }

    @media #{$vpmobile} {
        >ul {
            >li {
                flex: 0 0 100%;
            }
        }
    }
}

.IE {
    .gridView {
        >ul {
            >li {
                flex: 0 0 23%;
            }
        }
    }
}

.popup-is-active {
    overflow: hidden;
}

.loadingDv {
    text-align: center;
    padding: 50px 0 25px 0;
    position: relative;
    min-height: 134px;

    span {
        font-size: 9px;
        font-weight: 300;
        text-transform: uppercase;
        color: $iPink;
        margin-top: 7px;
        display: block;
    }

    >span {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 106px;
        transition: all 0.5s ease;
        height: 30px;
    }

    .loadmorebtn {
        display: none;

        >img {
            width: 49px;
        }
    }
}

.dropdown-wrap {
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 0 0 5px 5px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        border-bottom: none;
        list-style: none;
        transition: all 0.3s ease-out;
        max-height: 0;
        overflow: hidden;
        z-index: 4;
        min-width: 207px;

        li {
            padding: 0 10px;
            display: block;
            margin: 0;

            a {
                display: block;
                text-decoration: none;
                color: $iPink;
                padding: 10px 0;
                transition: all 0.3s ease-out;
                border-bottom: 1px solid #e6e8ea;
                font-size: 12px;
            }

            &:last-of-type a {
                border: none;
            }

            &:hover a {
                color: $iMagenta;
            }
        }
    }

    &.is--active {
        .filterBtn {
            background-color: $iMagenta;
        }

        .dropdown {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            max-height: 400px;
        }
    }
}

.row {
    &.pd_10 {
        margin-left: -10px;
        margin-right: -10px;

        [class*="col-"] {
            padding: 0 10px;
        }
    }
}

.locationBg {
    position: relative;

    @media #{$vpmobile} {
        min-height: 610px;
    }
}

.bgImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    top: 0;
    left: 0;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        opacity: 1;
        transition: all 0.5s ease-in-out;
        background: -webkit-repeating-linear-gradient(145deg,
                rgba(64, 64, 64, 0.5),
                rgba(64, 64, 64, 0.5) 2px,
                hsla(0, 0%, 100%, 0.1) 0,
                hsla(0, 0%, 100%, 0.1) 7px);
        background: -o-repeating-linear-gradient(145deg,
                rgba(64, 64, 64, 0.5),
                rgba(64, 64, 64, 0.5) 2px,
                hsla(0, 0%, 100%, 0.1) 0,
                hsla(0, 0%, 100%, 0.1) 7px);
        background: repeating-linear-gradient(-55deg,
                rgba(64, 64, 64, 0.5),
                rgba(64, 64, 64, 0.5) 2px,
                hsla(0, 0%, 100%, 0.1) 0,
                hsla(0, 0%, 100%, 0.1) 7px);
    }

    .bg1 {

        &:before,
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: all 0.5s ease-in-out;
        }

        &:before {
            z-index: 2;
            background-color: #242424;
            opacity: 0.97;
        }

        &:after {
            z-index: 3;
            background: #6b89b7;
            background: -webkit-linear-gradient(320deg, #6b89b7 40%, #ba287f);
            background: -o-linear-gradient(320deg, #6b89b7 40%, #ba287f);
            background: linear-gradient(130deg, #6b89b7 40%, #ba287f);
            opacity: 0.5;
        }
    }

    &.style1 {
        z-index: 1;

        &:before {
            background: -webkit-repeating-linear-gradient(145deg,
                    rgba(144, 144, 144, 0.5),
                    rgba(144, 144, 144, 0.5) 2px,
                    hsla(0, 0%, 100%, 0.1) 0,
                    hsla(0, 0%, 100%, 0.1) 7px);
            background: -o-repeating-linear-gradient(145deg,
                    rgba(144, 144, 144, 0.5),
                    rgba(144, 144, 144, 0.5) 2px,
                    hsla(0, 0%, 100%, 0.1) 0,
                    hsla(0, 0%, 100%, 0.1) 7px);
            background: repeating-linear-gradient(-55deg,
                    rgba(144, 144, 144, 0.1),
                    rgba(144, 144, 144, 0.1) 2px,
                    hsla(0, 0%, 100%, 0.05) 0,
                    hsla(0, 0%, 100%, 0.05) 3.4px);
        }
    }
}

.mouseanimateDv {
    display: inline-block;
    text-align: center;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 1.7s ease-in-out;
    opacity: 0;
    z-index: 1;
    visibility: hidden;

    .toSvg {
        width: 28px;
        height: 47px;
        display: inline-block;
        transition: all 1s ease-in-out;
        margin-bottom: 13px;
        position: absolute;
        left: 50%;
        top: -100%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);

        .anim-path1,
        .anim-path2,
        .anim-path3,
        .anim-path4 {
            animation: mouseanimate 1s infinite;
        }

        .anim-path1,
        .anim-path3 {
            animation-delay: 0.1s;
            animation-direction: alternate;
        }

        .anim-path2,
        .anim-path4 {
            opacity: 0;
            animation-delay: 0.3s;
            animation-direction: alternate;
        }

        &.touchIcon {
            display: none;
        }

        @media #{$vptabletP} {
            &.touchIcon {
                display: inline-block;
            }

            &.mouseIcon {
                display: none;
            }
        }
    }

    .blurFont {
        opacity: 0;
        transition: all 1s ease-in-out;
        visibility: hidden;
    }

    span {
        padding-top: 11px;
        display: block;
        line-height: 9px;
        text-transform: uppercase;
        color: $white;
        font-weight: 300;
        font-size: 12px;
        transition: all 1s ease-in-out;
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &.slideMRight {
            top: calc(50% + 47px);
            transform: translate(50%, -50%);
        }

        &.slideMLeft {
            top: calc(50% + 67px);
            transform: translate(-50%, -50%);
        }
    }

    .bgImg {
        opacity: 0;
        transition: all 1.5s ease-in-out;
        visibility: hidden;
    }

    .slideMTop {
        top: -100%;
        opacity: 0;
        visibility: hidden;
    }

    .slideMLeft {
        left: -100%;
    }

    .slideMRight {
        right: -100%;
    }

    &.showMAnimate {
        opacity: 1;
        visibility: visible;

        .bgImg {
            opacity: 1;
            visibility: visible;
        }

        .slideMTop {
            top: 50%;
            opacity: 1;
            visibility: visible;
        }

        .blurFont {
            opacity: 0.04;
            visibility: visible;

            .IE & {
                opacity: 0.02;
            }
        }

        .slideMLeft {
            left: 50%;
            opacity: 1;
            visibility: visible;
        }

        .slideMRight {
            right: 50%;
            opacity: 1;
            visibility: visible;
        }
    }
}

@-webkit-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.plyr--full-ui {
    input[type="range"] {
        color: $white;
    }

    .plyr__controls {

        .plyr__menu,
        .plyr__time {
            display: none;
        }

        .plyr__control {
            width: 42px;
            height: 34px;
            padding: 0;

            // svg.icon--not-pressed {
            //     use {
            //         opacity: 0;
            //     }
            // }
            &[data-plyr="play"] {
                svg {
                    height: 20px;
                    width: 17px;
                    margin: auto;

                    use {
                        opacity: 0;
                    }
                }

                svg.icon--pressed {
                    background: url(../img/icons/ic-pause.svg) no-repeat 0 0 scroll;
                }

                svg.icon--not-pressed {
                    background: url(../img/icons/ic-play.svg) no-repeat 0 0 scroll;
                    width: 20px;
                }
            }

            &[data-plyr="fullscreen"] {
                margin-left: 10px;

                svg {
                    margin: auto;
                }

                svg.icon--pressed {}

                svg.icon--not-pressed {
                    background: url(../img/icons/ic-fullscreen.svg) no-repeat 0 0 scroll;
                    height: 26px;
                    width: 34px;

                    use {
                        opacity: 0;
                    }
                }
            }

            &[data-plyr="mute"] {
                svg {
                    height: 26px;
                    width: 25px;
                    margin: auto;

                    use {
                        opacity: 0;
                    }
                }

                svg.icon--pressed {
                    background: url(../img/icons/ic-mute.svg) no-repeat 0 0 scroll;
                }

                svg.icon--not-pressed {
                    background: url(../img/icons/ic-volume.svg) no-repeat 0 0 scroll;
                }
            }
        }

        .plyr__volume {
            margin-left: 10px;
        }
    }
}

.plyr--video {
    .plyr__controls {
        .plyr__control {

            &.plyr__tab-focus,
            &:hover,
            &[aria-expanded="true"] {
                background: $iGreen;
            }
        }
    }
}

.plyr__control--overlaid {
    background: $iGreen;

    &:hover,
    &:focus {
        background: $iGreen;
    }
}

.css-scrollable {
    .mCSB_scrollTools {
        .mCSB_draggerRail {
            background-color: rgba(0, 0, 0, 0.2);
        }

        .mCSB_dragger {
            .mCSB_dragger_bar {
                background-color: rgba(60, 63, 64, 0.4);
            }
        }
    }
}

.btn-dft {
    background: $iOrang;
    outline: none;
    text-align: center;
    padding: $size-20;
    @extend .fz-14;
    color: #fff;
    display: inline-block;
    letter-spacing: 1.2px;
    position: relative;
    overflow: hidden;
    font-family: $neueMid;

    &::before {
        content: "";
        width: 80px;
        height: 80px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: $iPrimary;
        transition: all 0.5s ease;
        transform: translate(-50%, -50%) scale(0);
        border-radius: 100%;
    }

    span {
        position: relative;
        z-index: 1;
    }

    &:hover {
        color: #fff;
        transition: all 1s ease;

        &::before {
            transform: translate(-50%, -50%) scale(5);
        }
    }
}

.read-more-btn {
    padding: 10px 45px 10px 0;
    color: $iOrang;
    position: relative;
    @extend .fz-14;
    transition: all 0.3s ease;
    display: inline-block;
    font-family: $neueMid;

    &::after {
        content: "";
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid $iOrang;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &-icon {
        width: 25px;
        position: absolute;
        height: 8px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: $iOrang;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            right: 0;
            top: 0;
            border-right: 1px solid $iOrang;
            border-bottom: 1px solid $iOrang;
            transform: rotate(-45deg);
        }
    }

    &:hover {
        color: $iOrang;
        opacity: 0.7;

        .read-more-btn-icon {
            right: -12px;
        }
    }
}

.sp-tp {
    margin-top: $size-80;
}

.sp-bt {
    margin-bottom: $size-80;
}

.dz-default {
    height: 100% !important;

    .divimage {
        height: 160% !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

.dz-item {
    .divimage {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        height: 160% !important;
    }
}

.text-align-right {
    text-align: right;
}

.parallex-img {
    transform-style: preserve-3d
}

.services-list-item {
    &.set-text-align {
    }
    .services-list-item-text {
        transform: translateZ(30px) translateX(7.5px);
        @media (max-width: 1080px) {
            transform: translateZ(0px);
        }
    }
}

.innerTrans {
    position: fixed;
    top: 0;
    height: 100%;
    width: 0;
    background: $iMustard;
    transform: translateX(-50px);
    transition: 2s all ease-in-out;
    z-index: 99;
    // box-shadow: 5px 10px 5px #eaeaea;
}

.animTrans {
    animation: anim 4s ease-in-out;
}

.sec-padding-x {
    padding: 0 45px;

    @media(max-width:575px) {
        padding: 0;
    }
}

@keyframes anim {
    0% {}

    20% {
        transform: translateX(-100%);
    }

    40% {
        transform: translateX(0);
        width: 100%;
    }

    60% {
        transform: translateX(0);
        width: 100%;
    }

    80% {
        transform: translateX(-100%);
        width: 0;
    }

    100% {
        transform: translateX(-100%);
        width: 0;
    }
}

.c-loader-pixi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    background: #fff;
    z-index: 999;
    canvas {
        width: 100%;
        height: 100%;
    }
}

.profile-popup {
    .modal-dialog .modal-content {
        border: none;
        background: #F7F3F1;
    }
    &.modal {
        padding-right: 0 !important;
    }
    .modal-dialog {
        width: 100% !important;
        max-width: none !important;
        padding: 0px;
        margin: 0;
    }
    .modal-dialog .modal-content .modal-header {
        top: 10px;
        right: 100px;
        @media (max-width: 990px) {
            right: 20px;
        }
    }
    .modal-dialog .modal-content {
        margin-top: 0;
        padding-top: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid #707070;
    }
    .modal-dialog .modal-content .modal-header button {
        color: #000000;
        font-size: 16px;
        line-height: 1.4em;

        @media (max-width: 767px) {
            font-size: 12px;
        }
        span {
            font-size: 46px;
            position: relative;
            top: 9px;
            margin-right: 4px;

            @media (max-width: 990px) {
                font-size: 32px;
                top: 6px;
            }
            @media (max-width: 767px) {
                font-size: 25px;
                top: 4px;
            }
        }
    }
    .modal-dialog .modal-content .modal-body {
        padding: 75px 20px 20px;
        max-width: 840px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 767px) {
            padding-top: 20px;
        }
    }
    .modal-header {
        z-index: 2 !important;
    }
    .deco-1 {
        background: url(../img/animate-item-cross-x.png) no-repeat left bottom/auto 100%;
        position: absolute;
        bottom: 100%;
        left: 100%;
        transform: translate(-50%, 50%);
        height: 300px;
        width: 300px;
    }
    .deco-2 {
        background: url(../img/animate-item-circle.png) no-repeat left bottom/auto 100%;
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translate(50%,-50%);
        height: 300px;
        width: 300px;
    }
    .carousel-wrap {
        height: 100%;
        display: flex;
        vertical-align: middle;
        align-items: center;
    }
    .owl-carousel {
        opacity: 0;
        transition: all .35s;
        &.this-initialized {
            opacity: 1;
        }
    }
    .owl-carousel {
        overflow: hidden;
        .owl-nav {
            width: 219px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;

            @media (max-width: 767px) {
                width: 100%;
                padding-top: 0px;
            }

            .nav-txt {
                @extend .fz-14;
                line-height: 1em;
                color: #ED7800;
                display: inline-block;
                padding: 0 10px;
            }
            .owl-next,
            .owl-prev {
                display: inline-block;
                width: 28px;
                height: 20px;
                display: inline-block;
                background: url(../img/slider-nav-left.svg) no-repeat;
                outline: none;
                &.disabled {
                    pointer-events: none;
                    opacity: .25;
                }
                span{
                    display: none;
                }
            }
            .owl-next {
                background-image: url(../img/slider-nav-right.svg);
            }
        }
    }
    &.fade .modal-dialog {
        transform: translateY(33.33%);
    }
    &.show .modal-dialog {
        transform: none;
    }
}
.c-profile-item {
    display: flex;

    @media (max-width: 767px) {
        display: block;       
    }
    .img-wrap {
        width: 219px;
        img {
            display: block;
            @media (max-width: 767px) {
                display: block;
                max-height: calc(100vh - 490px);
                max-height: calc((var(--vh, 1vh) * 100) - 490px);
                min-height: 175px;
                width: auto;
            }
        }
    }
    .txt-wrap {
        padding-left: 30px;
        padding-right: 30px;
        color: #434C4A;
        width: calc(100% - 219px);
        padding-top: 20px;
        @media (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            p {
                margin-bottom: 15px;
            }
        }
        p {
            color: #434C4A !important;
        }
        h1 {
            font-family: 'NeueHaasDisplay-Mediu';
            font-size: 24px;
            color: #232726 !important;
        }
        .sub-title {
            margin-top: 0;
        }
    }
}

.c-ul {
    li {
        margin-bottom: 19px;
        padding-left: 32px;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top:  4px;
            left: 0;
            width:  16px;
            height: 16px;
            border: 2px solid $iBlack1;border-radius: 999px;
        }
    }
}

.only-desktop {
    @media (max-width: 767px) {
        display: none;
    }
}

.only-mobile {
    @media (min-width: 768px) {
        display: none;
    }
}

.c-symbol-heading {
    padding: 22px 0 8px 220px;
    background: url('../img/shape-logo.svg') no-repeat left top / 150px auto;
    margin-bottom: 0 !important;

    @media (max-width: 1366px) {
        padding: 17px 0 3px 210px;
        background-position: 10px top;
        background-size: 140px auto;
    }
    @media (max-width: 991px) {
        padding: 12px 0 0px 170px;
        background-size: 120px auto;
    }
    @media (max-width: 767px) {
        padding: 12px 0 5px 100px;
        background-size: 60px auto;
    }
    @media (max-width: 500px) {
        padding: 3px 0 3px 58px;
        background-size: 40px auto;
        font-size: 3rem !important;
        max-width: none !important;
        background-position: 0 top;
    }

    &.sh--cross { background-image: url('../img/shape-cross.svg'); }
    &.sh--lines { background-image: url('../img/shape-line.svg'); }
    &.sh--arrow { background-image: url('../img/shape-arrow.svg'); }
    &.sh--circle { background-image: url('../img/shape-circle.svg'); }
}

.w-ui-container .w-vulcan-v2 > *:not(.w-vulcan--background) {
    display: none !important;
}