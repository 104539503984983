.form {
    h3 {
        font-size: $size-47;
        color: inherit;
        letter-spacing: 1px;
        font-family: $neueMid;
        color: $iBlack;

        @media(max-width:767px) {
            font-size: 2.1rem;
        }
    }

    p {
        @extend .fz-14;
        color: inherit;
        letter-spacing: 0.8px;
        margin-top: $size-24;
    }

    .form-group {
        margin-top: $size-20;
        position: relative;

        label {
            display: block;
            @extend .fz-14;
            letter-spacing: 0.5px;
            margin-bottom: $size-12;
            font-family: $neueMid;

            &.error {
                color: red;
                position: absolute;
                left: 0px;
                bottom: -25px;
                font-size: 12px;
                margin: 0;
            }
        }

        .select2-container {
            width: 100% !important;

            .select2-selection {
                border-radius: 0;
                border: none;
                height: 47px;

                .select2-selection__rendered {
                    line-height: 47px;
                    padding-left: 15px;
                    color: $iBlack;
                    @extend .fz-14;
                    letter-spacing: 0.5px;
                    background-color: $iGrey;
                }

                .select2-selection__arrow {
                    height: 46px;
                    width: 35px;

                    b {
                        border-color: transparent $iOrang $iOrang transparent;
                        border-width: 0 2px 2px 0;
                        height: 10px;
                        width: 10px;
                        transform: translateY(-50%) rotate(45deg);
                    }
                }
            }

            &.select2-container--open {
                .select2-selection {
                    .select2-selection__arrow {
                        b {
                            transform: translateY(0) rotate(225deg);
                        }
                    }
                }
            }

            &.select2-container--default {
                .select2-selection--single {
                    .select2-selection__placeholder {
                        color: rgba($iBlack1, .4);
                    }
                }
            }
        }

        input,
        textarea {
            height: 47px;
            border: none;
            border-radius: 0;
            width: 100%;
            padding: 0 15px;
            @extend .fz-14;
            letter-spacing: 0.5px;
            background-color: $iGrey;
        }

        textarea {
            height: 120px;
            padding: 15px;
        }

        .select-input {
            display: flex;
            flex-wrap: wrap;

            .select2-container {
                width: 90px !important;
                // border-right: 1px solid rgba(0, 0, 0, 0.2);
            }

            input {
                width: calc(100% - 90px);
            }
        }

        .field-text {
            text-align: right;
            @extend .fz-14;
            letter-spacing: 0.5px;
        }

        button {
            border: none;
            outline: none;
            width: 287px;

            @media (max-width: 767px) {
                display: block;
                width: 100%;
                // max-width: 450px;
                // margin-right: auto;
                // margin-left: auto;
            }
        }
    }
}

.select2-container {
    .select2-dropdown {
        border: none;
    }

    .select2-results__option {
        @extend .fz-14;
        letter-spacing: 0.5px;
        padding: 10px 15px;
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $iOrang !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control,
.textarea {
    color: $iBlack1;
    @include placeholder(rgba($iBlack1, .4));
}

@media screen and (max-width: 1024px) {
    .form-popup .modal-dialog {
        padding: 15px;
    }
}