.contact-us {
    .animate-item-dual-arrow {
        right: -100px;
        top: 0;
    }

    &.animate-item {
        margin-top: 2rem;
    }

    &-map {
        &-img {
            img {
                width: 100%;
            }
        }

        &-text {
            padding-left: 30px;

            .map-address {
                margin: 30px 0 0 0;

                strong {
                    font-size: $size-24;
                    margin: 0px 0 10px 0;
                    display: block;
                    font-family: $neueMid;
                }

                p {
                    margin: 0;

                    a {
                        color: $iPrimary;
                        @extend .fz-14;
                        opacity: 1;
                        transition: all .3s ease;

                        &:hover {
                            opacity: .5;
                        }
                    }
                }
            }

            @media(max-width:991px) {
                padding-left: 0;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .contact-us-map-text .map-address {
        margin: 10px 0 0;
    }
}

@media screen and (max-width: 767px) {
    .contact-us-map {
        .row {
            .col-md-5 {
                order: -1;
                padding: 0 30px 30px;
            }
        }
    }
}