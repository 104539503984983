.text-layout {
    margin-top: 8rem;

    h1 {
        color: $iPrimary;
        font-family: $neueMid;
    }

    &-text {
        > strong {
            font-size: 18px;
            line-height: 1.2em;
            margin-bottom: 14px;
            display: block;
            margin-top: 35px;
        }
        ul {
            list-style: disc;
            padding-left: 12px;
        }
        ul, ol {
            li {
                margin: 15px 0 0 0;
            }
        }
        ol {
            padding: 0 0 0 13px;
            margin-bottom: 30px;

            li {
                padding-left: 8px;
            }
        }
    }
}