.modal-backdrop.show {
    opacity: .8;
}

.modal-dialog {
    max-width: 1128px !important;
    margin: .5rem auto;

    .modal-content {
        background-color: rgba($black, 1);
        border: 5px solid rgba($iOrang, .5);
        border-radius: 0;
        margin-top: 50px;

        &.modal-bg {
            background-size: cover;
        }

        .modal-header {
            border: none;
            position: absolute;
            right: -23px;
            top: -70px;
            z-index: 1;

            button {
                font-family: $neueMid;
                color: $iOrang;
                opacity: 1;
                font-size: 31px;
                outline: none;
                text-shadow: none;
            }

            @media(max-width:575px) {
                top: -60px;
            }
        }

        .modal-body {
            padding: $size-30 $size-100;
            color: $iPrimary;

            h3 {
                color: $white;
                font-family: $neueBlack;
                font-size: 2.94rem;
            }

            p {
                color: $white;
            }

            .form {
                margin: $size-30 0 0;

                .form-group {
                    label {
                        color: $white;
                    }

                    input,
                    textarea {
                        border: 1px solid $white;
                        background-color: transparent;
                        color: $white;
                        @include placeholder(rgba($white, .4));
                    }

                    .select2-container {
                        .select2-selection {
                            .select2-selection__rendered {
                                background-color: transparent;
                                color: $white;
                            }
                        }
                        &.select2-container--default {
                            .select2-selection--single {
                                .select2-selection__placeholder {
                                    color: rgba($white, .4);
                                }
                            }
                        }
                    }

                    .select2-container--default {
                        .select2-selection--single {
                            background-color: transparent;
                            border: 1px solid $white;
                        }
                    }

                    .select-input {
                        .select2-container {
                            border-right: 0;
                        }

                        .select2-container--default {
                            .select2-selection--single {
                                border-right: 0;
                            }
                        }

                        input {
                            width: calc(100% - 90px);
                        }
                    }

                    button {
                        max-width: 100%;
                    }

                    .field-text {
                        color: $white;
                    }
                }
            }

            @media(max-width: 992px) {
                padding: $size-30;
            }

            @media(max-width: 575px) {
                padding: 20px;
            }
        }
    }

    @media(max-width:1150px) {
        max-width: 90% !important;
    }

    @media(max-width:575px) {
        max-width: 95% !important;
    }
}