header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    transition: all 0.3s ease;

    .container {
        position: relative;
        z-index: 1;
    }

    .head {
        position: relative;

        .logo {
            padding: 20px 0;

            #Path_3405 {
                fill: $white;
            }
        }

        .head-right-nav {
            float: right;
            display: flex;

            .ar-selector {
                margin-right: 15px;

                img {
                    transition: all 1s ease;

                    &.ar-white {
                        opacity: 0;
                        transform: translateX(50px);
                        position: absolute;
                    }
                }
            }

            .menu-btn {
                display: flex;
                align-items: center;
                position: relative;
                z-index: 1;
                cursor: pointer;

                .hamburg-btn {
                    width: 20px;
                    transition-duration: 0.5s;
                    margin: 0 10px 0 0;

                    &-icon {
                        transition-duration: 0.5s;
                        position: absolute;
                        height: 2px;
                        width: 20px;
                        top: 13px;
                        background-color: $white;

                        &:before {
                            transition-duration: 0.5s;
                            position: absolute;
                            width: 20px;
                            height: 2px;
                            background-color: $white;
                            content: "";
                            top: -5px;
                        }

                        &:after {
                            transition-duration: 0.5s;
                            position: absolute;
                            width: 15px;
                            height: 2px;
                            background-color: $white;
                            content: "";
                            top: 5px;
                            right: 0;
                        }
                    }

                    &.open {
                        
                        .hamburg-btn-icon {
                            background-color: $white;
    
                            &:before,
                            &:after {
                                background-color: $white;
                            }
                        }
                        .hamburg-btn-icon {
                            transition-duration: 0.5s;
                            background: transparent;
                            top: 11px;

                            &:before {
                                transform: rotate(45deg) translate(10px, -0.5px);
                                width: 15px;
                            }

                            &:after {
                                transform: rotate(-45deg) translate(4px, -0.5px);
                            }
                        }
                    }
                }

                span {
                    user-select: none;
                    letter-spacing: 2.2px;
                    @extend .fz-14;
                    font-family: $neueMid;
                    color: $white;
                    margin: 3px 0 0 0;
                    z-index: 1;
                    // transition: all .5s ease;
                    // opacity: 1;
                }
            }
        }
    }

    .menu {
        position: fixed;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        left: 0;
        top: 0;
        // padding-top: 11rem;
        /* 140px/16 */
        // background-color: $iPrimary;
        display: none;
        z-index: 0;
        padding-top: 18vh;

        @media (max-width: 767px) {
            @media (max-height: 635px) {
                padding-top: 115px;
            }
        }

        ul {
            padding-left: 150px;

            li {
                margin-bottom: 5rem;
                animation: FadeIn 1.5s ease;
                animation-fill-mode: both;
                opacity: 0;
                margin-bottom: 80px;
                @media (min-width: 768px) {
                    @media (max-height: 930px) {
                        margin-bottom: 70px;
                    }
                    @media (max-height: 880px) {
                        margin-bottom: 60px;
                    }
                    @media (max-height: 830px) {
                        margin-bottom: 50px;
                    }
                    @media (max-height: 780px) {
                        margin-bottom: 40px;
                    }
                    @media (max-height: 730px) {
                        margin-bottom: 30px;
                    }
                    @media (max-height: 680px) {
                        margin-bottom: 30px;
                    }
                    @media (max-height: 630px) {
                        margin-bottom: 20px;
                    }
                }
                @media (max-width: 767px) {
                    margin-bottom: 8vh;
                }

                &:nth-child(2) {
                    a {
                        &:before {
                            background: #da88d3;
                        }
                    }
                }

                &:nth-child(3) {
                    a {
                        &:before {
                            background: #5dd3cc;
                        }
                    }
                }

                &:nth-child(4) {
                    a {
                        &:before {
                            background: #74d896;
                        }
                    }
                }

                a {
                    color: $white;
                    font-size: $size-72;
                    font-family: $neueMid;
                    position: relative;
                    padding: 0 15px;
                    display: inline-block;
                    line-height: $size-63;
                    letter-spacing: 6px;

                    &:before {
                        content: "";
                        width: 0%;
                        height: 4px;
                        position: absolute;
                        right: 0%;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: $iOrang;
                        margin-top: 5px;
                        // opacity: 0;
                    }

                    &:hover,
                    &.active {
                        &:before {
                            animation: linkHover 1s ease;
                            animation-fill-mode: both;
                        }
                    }
                }
            }

            @media(max-width:991px) {
                padding-left: 70px;
            }

            @media(max-width:767px) {
                padding-left: 0;
                text-align: center;
            }
        }
    }

    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        top: calc(50% - 1px);
        transform: translate(-50%, -50%);
        background: $white;
        transition: all 0.3s ease;
        opacity: 0;
    }

    &.fixed {
        box-shadow: 0 0 18px rgba($black, .5);

        .head {
            .logo {
                #Path_3405 {
                    fill: $iPrimary;
                }
            }

            .head-right-nav {
                .menu-btn {
                    color: $iPrimary;

                    span {
                        color: $iPrimary;
                    }

                    .hamburg-btn-icon {
                        background-color: $iPrimary;

                        &:before,
                        &:after {
                            background-color: $iPrimary;
                        }
                    }
                }
            }
        }

        &:after {
            opacity: 1;
        }
    }
}

.menu-has-open {

    // overflow: hidden;
    header {
        z-index: 3;
        top: 0;

        .head {
            z-index: 1;

            .head-right-nav {
                .ar-selector {
                    margin-right: 30px;

                    img {
                        &.ar-white {
                            opacity: 1;
                            transform: translateX(0);
                            position: static;
                        }
                    }
                }

                .menu-btn {
                    color: $white;
                }
            }
        }

        &.fixed {
            .head {
                .logo {
                    #Path_3405 {
                        // fill: $white;
                    }
                }
            }
        }
    }

    &.scroll-down {
        header {
            top: 0;
        }
    }
}

.not-video {
    .head {
        .logo {
            #Path_3405 {
                fill: $iPrimary;
            }
        }

        .head-right-nav {
            .menu-btn {
                color: $iPrimary;

                span {
                    color: $iPrimary;
                }

                .hamburg-btn-icon {
                    background-color: $iPrimary;

                    &:before,
                    &:after {
                        background-color: $iPrimary;
                    }
                }
            }
        }
    }

    &.menu-has-open {
        header {
            .head {
                .logo {
                    #Path_3405 {
                        // fill: $white;
                    }
                }
            }
        }
    }
}

.scroll-down {
    header {
        top: -100px;
    }
}

.video-banner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh)*100);
    height: -webkit-fill-available;
    overflow: hidden;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@keyframes FadeIn {
    0% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@for $i from 1 through 10 {
    header .menu ul li:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s;
    }
}

@keyframes linkHover {
    0% {
        right: 0%;
        opacity: .1;
        width: 0%;
    }
    50% {
        opacity: 1;
        right: 50%;
        width: 50%;
    }
    100% {
        opacity: 1;
        width: 100%;
        right: 0%;
    }
}


@media screen and (max-width: 1024px) {
    .menu-has-open header .head .head-right-nav .menu-btn span {
        // display: none;
    }
}

.menu-btn {
    .txt-close {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        color: #fff !important;
        z-index: 0;
    }
}

.menu-ripple {
    background: #fff;
    border-radius: 9999px;
    position: fixed;
    z-index: -1;
    transform: translate(-50%, -50%) scale(0);
    top: 47px;
    left: calc(50% + 728px);
    width:  100vh;
    height: 100vh;
    transform-origin: center center;

    @media (orientation: landscape) {
        width:  100vw;
        height: 100vw;
    }

    @media (max-width: 1613px) {
        left: calc(100% - 77px);
    }

    &.ripple--2 {
        background: #434d4b;
    }
}
header .head .head-right-nav .menu-btn .hamburg-btn-icon,
header .head .head-right-nav .menu-btn .hamburg-btn-icon:before,
header .head .head-right-nav .menu-btn .hamburg-btn-icon:after {
    transition: none;
}

.menu-opening header .head .logo #Path_3405 {
    fill: #fff;
}
.menu-opening header.fixed:after {
    opacity: 0 !important;
}
.menu-opening header.fixed {
    box-shadow: none;
}

// .menu {
//     position: fixed;
//     width: 100%;
//     height: 100vh;
//     left: 0;
//     top: 0;
//     padding-top: 11rem;
//     display: none;
//     z-index: -1;
// }