.building-brands {
    // @media (min-width: 768px) {
    //     &.services-list .col-12:nth-of-type(2n) .services-list-item {
    //         // margin-top: 0;
    //     }
    // }
    &.content-box {
        padding: $size-47 0 0;
    }

    &-content {
        &-text {
            ul,
            p {
                margin: 0px 0 25px;
            }

            p + ul {
                margin-top: -10px;
            }
            ul {
                margin-top: 0;
                padding-left: 12px;
            }
            li {
                list-style-type: disc;
            }
        }

        &-img {
            width: 420px;
            height: 500px;
            display: inline-block;
            margin: 0 0 70px 0;
        }
    }

    &.services-list {
        margin-top: 3rem;

        .sec-head {
            margin-bottom: 2rem;
        }
    }
}

@media screen and (max-width: 992px) {
    .building-brands.content-box {
        margin-bottom: 2rem;
    }

    .building-brands-content-img {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .building-brands-content .text-align-right {
        padding: 0;
    }

    .building-brands-content-img {
        height: 400px;
    }

    .building-brands.content-box {
        margin-bottom: 30px;
        margin-top: 5rem;
    }
}