// .home-header {
//     header .container {
//         max-width: 96%;
//     }
// }

.home-banner {
    height: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: relative;

    .container {
        height: 100%;
    }

    .row {
        height: 100%;
        z-index: 1;
        position: relative;

        .col {
            padding: 0;
        }
    }

    &-text {
        width: 100%;
        // max-width: 44rem;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        h1 {
            font-size: 5rem;
            font-family: $neueMid;
            color: $white;
            letter-spacing: 3px;
            line-height: 1.12;
        }

        p {
            font-size: 1rem;
            letter-spacing: 1px;
            color: $white;
        }
    }

    &-btn {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;

        .btn-dft {
            width: 100%;
            max-width: 17.9375rem;
            /* 287px/16 */
        }
    }

    &-img {
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        position: relative;
        right: 0;

        .dzsparallaxer {
            height: 100% !important;

            .divimage {
                height: 120% !important;
                background-repeat: no-repeat;
            }
        }

        .banner-img-mask {
            width: calc(100% + 1px);
            position: absolute;
            left: -1px;
            bottom: 0;
            background: url('../img/home-banner-mask.svg') no-repeat;
            height: 112%;
            background-size: cover;
            background-position: left bottom;
        }
    }

    &:after {
        content: "";
        position: absolute;
        right: 0%;
        top: 0px;
        height: 100%;
        width: 50%;
        background-image: url(../img/banner-mask.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 70% auto;
        z-index: 0;
        opacity: 0.5;
        transform: translateZ(0);
    }

}

.home-content {
    color: $iBlack;
    position: relative;

    h2 {
        font-size: $size-47;
        color: inherit;
        letter-spacing: 1px;
        opacity: 0.9;
        font-family: $neueMid;
        line-height: 1;
    }

    p {
        color: $iBlack1;
        letter-spacing: 0.5px;
        margin-top: $size-26;
    }

    .animate-item-circle {
        left: inherit;
        right: -60px;
        top: -50px;
    }
}

.keyword-slider {
    overflow: hidden;
    height: 650px !important;

    .container {
        position: relative;
        height: 100%;
    }

    &-img {
        height: 100% !important;
        position: absolute !important;
        width: calc(100% - 30px);
        transition: all 1.2s ease !important;

        &.dzsparallaxer {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: inherit;
                transition: all 1s ease !important;
            }

            .divimage {
                height: 130% !important;
                opacity: 0.5;
            }
        }
    }

    &-items {
        height: 100%;
        overflow: auto;
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-around;
            height: 510px;
            margin-top: 60px;

            li {
                width: 100%;
                font-family: $neueMid;
                font-size: $size-72;
                color: $white;
                text-align: center;

                span {
                    opacity: 0.3;
                    transition: all 1.2s ease;
                    cursor: pointer;

                    a {
                        color: $white;
                    }

                    &:hover {
                        opacity: 1;
                    }
                }

                &.active {
                    span {
                        opacity: 1;
                    }
                }
            }
        }

        #mCSB_1_scrollbar_vertical {
            right: 10px;
        }

        .mCSB_inside>.mCSB_container {
            margin: 0;
            text-align: center;
            height: 100%;
        }
    }

    .animate-item-col-lines {
        right: -110px;
        bottom: 20%;
    }
}

.home-footer {
    footer {
        padding-top: 0px;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 1366px) {
    .home-content h2 {
        font-size: 2.5rem;
    }

    .keyword-slider-items ul li {
        font-size: 4rem;
    }

    .home-banner-text h1 {
        font-size: 4rem;
    }

    .home-banner-text h1 br {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    // .home-banner {
    //     height: auto;
    //     padding-top: 92px;
    // }

    // .home-banner-img {
    //     height: 528px;
    // }

    // .home-banner-img .banner-img-mask {
    //     height: 100%;
    // }

    // .home-banner-text,
    // .home-banner-btn {
    //     position: static;
    //     transform: none;
    // }

    .home-banner .row .col:first-of-type {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    // .home-banner-text {
    //     padding: 0 24px;
    // }

    .keyword-slider-items ul {
        align-content: space-evenly;
    }

    .keyword-slider-items ul li {
        font-size: 3.4rem;
    }

    .home-banner-text h1 {
        font-size: 3.4rem;
    }

    .home-banner:after {
        background-size: 90% auto;
    }
}

@media screen and (max-width: 992px) {
    // .home-header {
    //     position: static;
    // }

    // .home-banner .row {
    //     // flex-direction: column-reverse;
    // }

    // .home-banner-img {
    //     width: 500px;
    //     display: inline-block;
    // }

    // .home-banner-text {
    //     max-width: none;
    //     position: static;
    //     transform: none;
    //     margin-top: $size-20;
    // }

    // .home-banner-btn {
    //     position: static;
    //     margin-top: $size-26;
    //     margin-left: -15px;
    // }

    // .services-list .col-12 {
    //     text-align: right;
    // }

    // .services-list-item {
    //     max-width: 320px;
    //     display: inline-block;
    // }

    // .services-list-item-text {
    //     text-align: left;
    // }
}

@media screen and (max-width: 991px) {
    .home-banner .row .col-sm-12:nth-of-type(2) {
        text-align: right;
        order: -1;
    }

    .services-list-item-text {
        padding: 20px 20px 25px;
    }

    .home-banner:after {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .home-banner-text h1 {
        font-size: 4rem;
        padding-left: 10px;
    }

    // .home-banner-img {
    //     width: 286px;
    //     height: 286px;
    //     right: -25px;
    // }

    .sp-tp {
        margin-top: 3rem;
    }

    .sp-bt {
        margin-bottom: 3rem;
    }

    .home-content h2 {
        font-size: 2rem;
    }

    header .menu ul li a {
        font-size: 3.215rem;
        line-height: 1rem;
    }

    header .menu ul li a:before {
        margin-top: 1px;
    }

    .services-list .col-12 {
        text-align: left;
    }

    .services-list .col-12:not(:last-of-type) {
        margin-bottom: 40px;
    }

    // .services-list .col-12:nth-of-type(odd) .services-list-item {
    //     left: 30px;
    // }

    // .services-list .col-12:nth-of-type(even) {
    //     text-align: right;
    // }

    // .services-list-item {
    //     max-width: 260px;
    // }

    .services-list-item-img {
        height: 275px !important;
    }

    .services-list-item-text h3 {
        font-size: 1.75rem;
        margin-bottom: 1.2rem;
    }

    .keyword-slider {
        height: 590px !important;
    }

    .keyword-slider .container {
        padding: 0;
    }

    .keyword-slider-img {
        width: 100%;
    }

    .keyword-slider-items ul li {
        font-size: 3.2rem;
    }
}

@media(max-width:575px) {
    .services-list .col-12:nth-of-type(2n) .services-list-item {
        margin: 2rem auto;
    }

    .services-list-item-img {
        height: 436px !important;
    }
}

@media(max-width:480px) {
    .services-list-item-img {
        height: 375px !important;
    }

    .keyword-slider-items ul li {
        font-size: 2.5rem;
    }

    .keyword-slider-items ul {
        margin-top: 40px;
    }
}

@media(max-width:360px) {

    // .services-list-item {
    //     max-width: 230px;
    // }
    header .menu ul li a {
        font-size: 2.5rem;
        line-height: 1rem;
    }

    .services-list-item-img {
        height: 300px !important;
    }
}