.footer {
    text-align: center;
    position: relative;
    padding-top: 60px;
    background: #fff;

    &:before {
        content: "";
        width: 84%;
        height: 3px;
        position: absolute;
        top: 0;
        right: 0;
        background: #C88EB3;
    }

    h5 {
        font-size: $size-47;
        font-family: $neueMid;
        color: $iBlack;
    }

    p {
        @extend .fz-14;
        letter-spacing: 0.5px;
        margin-top: $size-20;
        color: $iBlack1;
    }

    .email-subscribe {
        display: flex;
        margin: $size-40 0 0;

        input {
            height: 48px;
            border: none;
            border-radius: 0;
            width: calc(100% - 190px);
            padding: 1px 15px 0;
            @extend .fz-14;
            letter-spacing: 0.5px;
            background-color: $iGrey;
        }

        .btn-dft {
            width: 190px;
            border: none;
            outline: none;
            padding: 11px 10px 9px 10px;
        }
    }

    .social-icons {
        width: 216px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $size-47 auto;

        a {
            svg path {
                transition: all 0.3s ease;
            }

            &:hover {
                svg path {
                    fill: $iOrang;
                }
            }
        }
    }

    .foot {
        background: $iBlack;
        padding: 25px 0;
        color: #fff;
        @extend .fz-14;
        position: relative;
        z-index: 1;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-text {
            a {
                color: #fff;
                transition: all 0.3s ease;
                display: inline-block;
                margin: 0 5px;

                &:hover {
                    opacity: 0.5;
                }
            }
        }
    }
    #mc_embed_signup form {
        padding: 0;
    }
    #mc_embed_signup {
        width: 100%;
        #mc-embedded-subscribe-form div.mce_inline_error {
            margin: 10px 0 0 0;
            padding: 0;
            color: #de7272;
            width: 100%;
        }
        div#mce-responses {
            margin: 10px 0 0 0;
            float: none;
            display: block;
            top: 0;
            width: 100%;
            padding: 0;
        }
        div.response {
            display: block;
            margin: 0;
            padding: 10px;
            background: #74d896;
            width: 100%;
            color: #fff;
            @extend .fz-14;
            text-align: center;
        }
        #mce-error-response {
            background: #de7272;
            color: #fff;
            a {
                display: block;
                color: #fff;
                text-decoration: underline !important;
            }
        }
        #mce-success-response {
            background: #74d896;
            color: #fff;
        }
    }
    #mc_embed_signup .mc-field-group {
        width: 100%;
        min-height: 0;
        padding-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        input {
            width: calc(100% - 190px);
            border-radius: 0;
            border: none;
            height: 48px;
            padding: 1px 15px 0;
            @extend .fz-14;
            letter-spacing: .5px;
            background-color: #f8f8f8;
            text-indent: 0;

            @media (max-width: 767px) {
                width: calc(100% - 140px);
            }
        }
        button {
            width: 190px;

            @media (max-width: 767px) {
                width: 140px;
            }
        }
    }
    
}

@media screen and (max-width: 1366px) {
    .footer h5 {
        font-size: 2.5rem;
    }

    .footer .email-subscribe .btn-dft {
        width: 150px;
    }

    .footer .email-subscribe input {
        width: calc(100% - 150px);
    }
}

@media screen and (max-width: 767px) {
    .footer {
        padding-top: 35px;
    }

    .footer h5 {
        font-size: 2.1rem;
    }

    .footer .email-subscribe input {
        width: calc(100% - 112px);
    }

    .footer .email-subscribe .btn-dft {
        width: 112px;
    }

    .footer .foot .container {
        flex-wrap: wrap;
    }

    .footer .foot .foot-text {
        width: 100%;
    }

    .footer .foot .foot-text:nth-of-type(2) {
        margin: 10px 0;
    }
}

.c-msg {
    margin: 10px 0 0 0;
    width: 100%;
    display: block;
    padding: 10px;
    background: #74d896;
    color: #fff;
    @extend .fz-14;
    text-align: center;
    
    a {
        display: block;
        color: #fff;
        text-decoration: underline !important;
    }

    &.msg--error {
        background: #de7272;
        color: #fff;
    }
    &.msg--successful {
        background: #74d896;
        color: #fff;
    }
}