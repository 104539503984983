.sports {
    &-text {
        margin-bottom: 30px;
        p {
            letter-spacing: 1.5px;
            line-height: 24px;
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &.content-box {
        margin-top: 3rem;

        p {
            margin-top: 45px;
        }
    }

    .gallery {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 35px;

        &-item {
            height: 215px;
            margin-bottom: 20px;

            &:nth-of-type(1) {
                width: 23.07%;
            }

            &:nth-of-type(2) {
                width: 31.61%;
            }

            &:nth-of-type(3) {
                width: 23.07%;
            }

            &:nth-of-type(4) {
                width: 14.6%;
            }

            &:nth-of-type(5) {
                width: 14.6%;
            }

            &:nth-of-type(6) {
                width: 23.07%;
            }

            &:nth-of-type(7) {
                width: 31.61%;
            }

            &:nth-of-type(8) {
                width: 23.07%;
            }
        }

        .owl-dots {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 7px 0 0 0;

            button {
                width: 32px;
                height: 3px;
                background: $iPrimary;
                transition: all .3s ease;
                border: none;
                outline: none;
                margin: 0 5px;

                &.active {
                    background: $iOrang;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .sports .gallery {
        margin-bottom: 30px;
    }

    .sports .gallery-item:first-of-type,
    .sports .gallery-item:nth-of-type(2),
    .sports .gallery-item:nth-of-type(3),
    .sports .gallery-item:nth-of-type(4),
    .sports .gallery-item:nth-of-type(5),
    .sports .gallery-item:nth-of-type(6),
    .sports .gallery-item:nth-of-type(7),
    .sports .gallery-item:nth-of-type(8) {
        width: 215px;
        animation: none;
        opacity: 1;
    }
}